<template>
  <section class="base-page">
    <section class="page-header base-shadow">
      <h3 class="header-title">{{ $route.meta.title }} {{$route.query.name ? `[${$route.query.name}]` : ''}}</h3>
    </section>
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-position="top"
      label-width="100px"
      :disabled="submitting"
      v-loading="loading"
      status-icon
      class="rule-form"
    >
      <FormBlock name="基本信息">
        <el-row :gutter="30">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="金额" prop="amount">
              <el-input
                type="number"
                v-model.trim="form.amount"
                clearable
                placeholder="请输入"
              >
                <span slot="prepend">￥</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="合同开始日期" prop="startDate">
              <el-date-picker
                style="width: 100%"
                :picker-options="startDatePickerOptions"
                v-model="form.startDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="点击选择"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="合同结束日期" prop="endDate">
              <el-date-picker
                style="width: 100%"
                :picker-options="endDatePickerOptions"
                v-model="form.endDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="点击选择"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="" prop="expireRemindDate">
              <span slot="label">
                合同到期提醒日期
                <el-popover
                  placement="top"
                  title="提示"
                  width="200"
                  trigger="click"
                  content="合同过期前的提醒日期，比如合同结束日期为2021-01-04，那么此处可以设置2021-01-01，当日期到2021-01-01时将会提醒用户合同即将过期"
                >
                  <i class="el-icon-info" slot="reference"></i>
                </el-popover>
              </span>
              <el-date-picker
                style="width: 100%"
                v-model="form.expireRemindDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="点击选择"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="合同签订地点" prop="signAddress">
              <el-input
                type="text"
                v-model.trim="form.signAddress"
                clearable
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </FormBlock>
      <FormBlock name="客户相关">
        <el-row :gutter="30">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户" prop="clientId">
              <BasicSelect
                v-model="form.clientId"
                :allOptions="_clientOptions"
              />
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户签字日期" prop="clientSignDate">
              <el-date-picker
                style="width: 100%"
                v-model="form.clientSignDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="点击选择"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户签字人姓名" prop="clientSignatoryName">
              <el-input
                type="text"
                v-model.trim="form.clientSignatoryName"
                clearable
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="客户签字人职位" prop="clientSignatoryPosition">
              <el-input
                type="text"
                v-model.trim="form.clientSignatoryPosition"
                clearable
                placeholder="请输入"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </FormBlock>
      <FormBlock name="公司相关">
        <el-row :gutter="30">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="公司签字人" prop="companySignatoryId">
              <el-select
                class="base-select"
                v-model="form.companySignatoryId"
                placeholder="请选择"
                clearable
                filterable
              >
                <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="公司签字日期" prop="companySignDate">
              <el-date-picker
                style="width: 100%"
                v-model="form.companySignDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="点击选择"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </FormBlock>
      <FormBlock name="其他">
        <el-row :gutter="30">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="备注" prop="remark">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入"
                v-model="form.remark"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="特殊条款" prop="term">
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入"
                v-model="form.term"
                clearable
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件" prop="fileIds">
              <el-button
                type="primary"
                size="small"
                @click="showFileDialog = true"
                >{{
                  fileList.length > 0
                    ? `已选${fileList.length}个附件`
                    : "选择文件"
                }}</el-button
              >
              <div class="file-content-list" v-if="fileList.length > 0">
                <template v-for="(item, index) in fileList">
                  <FilesItem
                    showDelete
                    :showCheckBox="false"
                    :item="item"
                    @onDelete="onFileDelete(index)"
                    :key="item.id"
                    class="mt-r"
                  />
                </template>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </FormBlock>
      <FormBlock name="提交操作">
        <el-row :gutter="30">
          <el-col :span="16" :offset="8" style="text-align: right">
            <el-button
              @click="resetForm('ruleForm')"
              v-if="currentId === null"
              :disabled="submitting"
              >重置</el-button
            >
            <el-button
              v-if="currentId === null"
              type="primary"
              @click="submitForm('ruleForm')"
              :loading="submitting"
              >立即创建</el-button
            >
            <el-button
              v-if="currentId !== null"
              type="warning"
              @click="editForm('ruleForm')"
              :loading="submitting"
              >提交修改</el-button
            >
          </el-col>
        </el-row>
      </FormBlock>
    </el-form>
    <el-dialog
      title="选择文件"
      :visible.sync="showFileDialog"
      append-to-body
      :close-on-click-modal="false"
      width="80%"
    >
      <FilesView
        ref="FilesView"
        :fileItems="fileList"
        @confirm="onFileConfirm"
        @cancel="onFileCancel"
      />
    </el-dialog>
  </section>
</template>

<script>
import FormBlock from "@/components/FormBlock";
import { createUniqueString } from "@/utils/common";
import contractsForm from "./mixins/contractsForm";
import formSelection from "./mixins/formSelection";
import { GetContractsById, PutContractsById, PostContracts } from "./api";
import { ShowApiError } from "@/request/error";
import FilesView from "@/views/filesModule/FilesView";
import FilesItem from "@/views/filesModule/components/FilesItem";
import BasicSelect from "@/components/BasicSelect";
export default {
  components: {
    FormBlock,
    FilesView,
    FilesItem,
    BasicSelect,
  },
  mixins: [contractsForm, formSelection],
  data() {
    return {
      startDatePickerOptions: {
        disabledDate: (time) => {
          if (this.form.endDate) {
            return time.getTime() > new Date(this.form.endDate).getTime();
          }
        },
      },
      endDatePickerOptions: {
        disabledDate: (time) => {
          if (this.form.startDate) {
            return (
              time.getTime() <= new Date(this.form.startDate).getTime() - 8.64e7
            );
          }
        },
      },
      showFileDialog: false,
      fileList: [],
      loading: false,
      submitting: false,
      areaValue: [],
      currentId: null,
      form: {
        amount: null,
        clientId: null,
        clientSignDate: "",
        clientSignatoryName: "",
        clientSignatoryPosition: "",
        companySignDate: "",
        companySignatoryId: null,
        endDate: "",
        expireRemindDate: "",
        fileIds: [],
        remark: "",
        signAddress: "",
        startDate: "",
        term: "",
      },
    };
  },
  watch: {
    $route: {
      handler() {
        if (
          this.$route.name === "ContractsForm" &&
          this.$route.params.id &&
          this.$route.params.id !== null
        ) {
          this.currentId = this.$route.params.id;
          this.getFormData(this.currentId);
        } else {
          this.currentId = null;
          this.$nextTick(() => {
            this.resetForm("ruleForm");
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    createUniqueString,
    setInsideParams() {
      if (this.$route.name === "ContractsForm" && this.currentId == null) {
        if(this.$route.params.clientId){
          this.form.clientId = Number(this.$route.params.clientId)
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PostContracts({
            ...this.form,
            fileIds: this.fileList.map((item) => item.id),
          })
            .then(() => {
              this.$message.success("新增成功");
              this.submitting = false;
              this.resetForm(formName);
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("新增客户失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查表单！");
          console.log("error submit!!");
          return false;
        }
      });
    },
    editForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutContractsById(this.currentId, {
            ...this.form,
            fileIds: this.fileList.map((item) => item.id),
          })
            .then(() => {
              this.$message.success("修改成功");
              this.submitting = false;
              this.resetForm(formName);
              this.getFormData(this.currentId);
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("修改客户失败", err);
            });
        } else {
          this.$message.warning("表单有误，请检查表单！");
          return false;
        }
      });
    },
    getFormData(id = "") {
      this.loading = true;
      GetContractsById(id)
        .then((res) => {
          for (const key in this.form) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              this.form[key] = res.data[key];
            }
          }
          this.fileList = res.data.files;
          this.form.fileIds = res.data.files.map((item) => item.id);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单数据失败", err);
        });
    },
    resetForm(formName) {
      this.initForm();
      this.$refs[formName].clearValidate();
    },
    onFileConfirm({ items }) {
      this.fileList = items;
      this.showFileDialog = false;
    },
    onFileCancel() {
      this.showFileDialog = false;
    },
    onFileDelete(index) {
      this.fileList.splice(index, 1);
    },
    initForm() {
      this.areaValue = [];
      this.form = {
        amount: null,
        clientId: null,
        clientSignDate: "",
        clientSignatoryName: "",
        clientSignatoryPosition: "",
        companySignDate: "",
        companySignatoryId: null,
        endDate: "",
        expireRemindDate: "",
        fileIds: [],
        remark: "",
        signAddress: "",
        startDate: "",
        term: "",
      };
      this.fileList = [];
      this.setInsideParams();
    },
  },
};
</script>

<style lang="scss" scoped>
.file-content-list {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.base-select {
  box-sizing: border-box;
  width: 100%;
}
.more-contatct-title {
  box-sizing: border-box;
  padding: 10px 0;
  text-align: left;
  font-size: 15px;
  color: #989898;
}
.add-more-contacter {
  width: 100%;
  box-sizing: border-box;
  border: 1px dashed #ddd;
  border-radius: 3px;
  padding: 5px 0;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  color: #989898;
  &:hover {
    background-color: #fcf8f8;
    color: #000;
  }
}
</style>