
export default {
    data() {
        return {
            rules: {
                amount: [{ required: true, message: "请输入金额", trigger: "blur" }],
                signAddress: [{ required: true, message: "请输入合同签订地点", trigger: "blur" }],
                clientId: [
                    {
                        required: true,
                        message: "请选择客户",
                        trigger: ["blur", "change"],
                    },
                ],
                endDate: [
                    {
                        required: true,
                        message: "请选择合同结束日期",
                        trigger: ["blur", "change"],
                    },
                ],
                expireDate: [
                    {
                        required: true,
                        message: "请选择合同过期日期",
                        trigger: ["blur", "change"],
                    },
                ],
                startDate: [
                    {
                        required: true,
                        message: "请选择合同开始日期",
                        trigger: ["blur", "change"],
                    },
                ]
            }
        }
    }
}