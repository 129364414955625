export default {
    computed:{
        _clientOptions(){
          return this.$store.state.basicOption.clientOptions
        },
        userOptions() {
            return this.$store.getters.userOptions ? this.$store.getters.userOptions : [];
          }
      },
    methods: {}
}